/* components/PriceEstimation.css */
.price-estimation-section {
    margin: 20px 0;
    text-align: left;
  }
  
  .price-estimation-section h2,
  .price-estimation-section h3 {
    margin-bottom: 10px;
  }
  
  .price-estimation-section p {
    margin-bottom: 20px;
  }
  