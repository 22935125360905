/* App.css */
.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

header {
  background-color: #f7f7f7;
  padding: 20px;
}

main {
  padding: 20px;
}

h1 {
  color: #333;
}

p {
  color: #555;
}
