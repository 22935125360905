/* components/MapComponent.css */
.map-section {
    margin: 20px 0;
  }
  
  .map-container {
    height: 500px;
    width: 100%;
    border: 1px solid #ccc;
  }
  